/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import {
  IconButton,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Button,
  Tooltip,
} from "@material-ui/core";
import { observer, useLocalStore } from "mobx-react-lite";
import { Menu as MenuIcon, Star } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import { useGlobalState } from "state";
import { AppState } from "App";
import { ShareModal } from "components/share_modal";
import { HowToUseModal } from "./HowToUseModal";
import { NewFavoriteGroupModal } from "components/favorites/new_favorite_group_modal";

export interface NavbarState {
  modalOpen: boolean;
  shareModalOpen: boolean;
  menuAnchor?: HTMLButtonElement;
  favoriteModalOpen: boolean;
}

export const Navbar = observer(
  ({ appLocalState }: { appLocalState: AppState }) => {
    const state = useGlobalState();

    const navbarState = useLocalStore<NavbarState>(() => ({
      modalOpen: false,
      shareModalOpen: false,
      favoriteModalOpen: false,
    }));

    const history = useHistory();

    function closeMenu() {
      navbarState.menuAnchor = undefined;
    }

    return (
      <AppBar color="default" position="relative">
        <Toolbar variant="dense">
          <Tooltip title="Export current streams">
            <Button
              color="inherit"
              onClick={() => (navbarState.shareModalOpen = true)}
            >
              Share
            </Button>
          </Tooltip>

          <Tooltip title="Save current group to favorites">
            <IconButton onClick={() => (navbarState.favoriteModalOpen = true)}>
              <Star />
            </IconButton>
          </Tooltip>

          <NewFavoriteGroupModal
            isOpen={navbarState.favoriteModalOpen}
            onRequestClose={() => (navbarState.favoriteModalOpen = false)}
          />

          <ShareModal navbarState={navbarState} />

          <div
            css={css`
              position: absolute;
              right: 16px;
            `}
          >
            {state.currentStream === null ? null : (
              <Button
                color="inherit"
                onClick={() => (state.currentStream = null)}
              >
                Back to MultiTwitch
              </Button>
            )}

            <Tooltip title="Menu">
              <IconButton
                onClick={(e) => (navbarState.menuAnchor = e.currentTarget)}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>

            <Menu
              anchorEl={navbarState.menuAnchor}
              open={navbarState.menuAnchor !== undefined}
              onClose={closeMenu}
              keepMounted
            >
              <MenuItem
                onClick={() => {
                  history.push("/");
                  closeMenu();
                }}
              >
                Stream List
              </MenuItem>

              <MenuItem
                onClick={() => {
                  history.push("/favorites");
                  closeMenu();
                }}
              >
                Favorites
              </MenuItem>

              <MenuItem
                onClick={() => {
                  history.push("/favorite-groups");
                  closeMenu();
                }}
              >
                Favorite Groups
              </MenuItem>

              <MenuItem
                onClick={() => {
                  history.push("/leaderboard");
                  closeMenu();
                }}
              >
                Leaderboard
              </MenuItem>

              <MenuItem
                onClick={() => {
                  navbarState.modalOpen = true;
                  closeMenu();
                }}
              >
                How to Use
              </MenuItem>

              <MenuItem>
                <a
                  css={css`
                    text-decoration: none;
                    color: white;
                    width: 100%;
                  `}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=JE63D2B77GYTJ&item_name=Donations+go+towards+development+and+server+costs.+Every+little+bit+helps%21&currency_code=USD&source=url"
                >
                  Support Us!
                </a>
              </MenuItem>

              <MenuItem>
                <a
                  css={css`
                    text-decoration: none;
                    color: white;
                    width: 100%;
                  `}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://discord.gg/vEmjtwmkk5"
                >
                  Discord
                </a>
              </MenuItem>

              <MenuItem onClick={closeMenu}>
                <a
                  href="mailto:bugs@watchfortnite.live?subject=[BUG REPORT]"
                  target="_blank"
                  rel="noopener noreferrer"
                  css={css`
                    text-decoration: none;
                    color: white;
                  `}
                >
                  Submit a bug report
                </a>
              </MenuItem>
            </Menu>
          </div>

          <HowToUseModal navbarState={navbarState} />
        </Toolbar>
      </AppBar>
    );
  }
);
