/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { autorun } from "mobx";
import { observer, useLocalStore } from "mobx-react-lite";
import { checkTwitch } from "checkTwitch";
import { useGlobalState } from "state";

import { useTheme, makeStyles } from "@material-ui/core/styles";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import {
  Typography,
  Button,
  TextField,
  CircularProgress,
  ListSubheader,
  useMediaQuery,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect } from "react";
import { API_URL } from "api";

export interface StreamInputState {
  streamName: string;
  streamNameSuggestions: string[];
  epicName: string;
  epicNameSuggestions: string[];
  streamError?: string;
  epicError?: string;
}

interface StreamInputProps {
  initialStreamName?: string;
  initialEpicName?: string;
  emptyStreamError: string;
  duplicateStreamError: string;
  duplicateEpicError: string;
  inactiveStreamError: string;
  buttonText: string;
  okayAction: (
    cleanStreamName: string,
    cleanEpicName: string,
    localState: StreamInputState
  ) => void;
  checkDuplicateStream: (
    listStreamName: string,
    trimmedStreamName: string
  ) => boolean;
  checkDuplicateEpic: (
    listEpicName: string | undefined,
    trimmedEpicName: string
  ) => boolean;
}

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactNode) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

const useStyles = makeStyles({
  listbox: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

export const StreamInput = observer(
  ({
    initialStreamName = "",
    initialEpicName = "",
    emptyStreamError,
    duplicateEpicError,
    duplicateStreamError,
    inactiveStreamError,
    buttonText,
    okayAction,
    checkDuplicateStream,
    checkDuplicateEpic,
  }: StreamInputProps) => {
    const state = useGlobalState();
    const localState = useLocalStore<StreamInputState>(() => ({
      streamName: initialStreamName,
      streamNameSuggestions: [],
      epicName: initialEpicName,
      epicNameSuggestions: [],
    }));
    const classes = useStyles();

    useEffect(() => {
      const epic_from_twitch_disposer = autorun(
        async () => {
          let res = await fetch(API_URL + "/epic_from_twitch", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              twitchName: localState.streamName.trim().toLowerCase(),
            }),
          });
          let new_res = await res.json();
          let epic_name_suggestions = new_res.map(
            (suggestion: any) => suggestion.epic_name
          );
          console.log(epic_name_suggestions);
          localState.epicNameSuggestions = epic_name_suggestions;
        },
        { delay: 300 }
      );
      return () => {
        epic_from_twitch_disposer();
      };
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      const twitch_from_epic_disposer = autorun(
        async () => {
          let res = await fetch(API_URL + "/twitch_from_epic", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              epicName: localState.epicName.trim().toLowerCase(),
            }),
          });
          let new_res = await res.json();
          let twitch_name_suggestions = new_res.map(
            (suggestion: any) => suggestion.twitch_name
          );
          console.log(twitch_name_suggestions);
          localState.streamNameSuggestions = twitch_name_suggestions;
        },
        { delay: 300 }
      );
      return () => {
        twitch_from_epic_disposer();
      };
      // eslint-disable-next-line
    }, []);

    async function addStream() {
      if (state.isAddingStream) {
        return;
      }

      state.isAddingStream = true;

      let trimmedStreamName = localState.streamName.trim();
      let trimmedEpicName = localState.epicName.trim();

      if (trimmedStreamName === "") {
        localState.streamError = emptyStreamError;
      }

      for (let i = 0; i < state.streamList.length; ++i) {
        if (
          checkDuplicateStream(
            state.streamList[i].streamName,
            trimmedStreamName
          )
        ) {
          localState.streamError = duplicateStreamError;
        }

        if (checkDuplicateEpic(state.streamList[i].epicName, trimmedEpicName)) {
          localState.epicError = duplicateEpicError;
        }
      }

      if (localState.epicError || localState.streamError) {
        state.isAddingStream = false;
        return;
      }

      let res = await checkTwitch(trimmedStreamName);

      if (res.isOkay) {
        okayAction(trimmedStreamName, trimmedEpicName, localState);
      } else {
        localState.streamError = res.error || inactiveStreamError;
      }

      state.isAddingStream = false;
    }

    function onKeyPress(e: any) {
      if (e.key === "Enter") {
        addStream();
      }
    }

    return (
      <div
        css={[
          css`
            display: flex;
            width: 100%;

            align-items: center;

            > * {
              margin: 10px;
            }
          `,
          (!!localState.epicError || !!localState.streamError) &&
            css`
              align-items: flex-start;
            `,
        ]}
      >
        <Autocomplete
          id="virtualize-demo"
          freeSolo
          disableListWrap
          classes={classes}
          ListboxComponent={
            ListboxComponent as React.ComponentType<
              React.HTMLAttributes<HTMLElement>
            >
          }
          options={localState.streamNameSuggestions}
          css={css`
            width: 200px;
          `}
          openOnFocus
          inputValue={localState.streamName}
          onInputChange={(event, newInputValue, reason) => {
            if (event !== null) {
              localState.streamName = newInputValue;
              localState.streamError = undefined;
            }
          }}
          disabled={state.isAddingStream}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Stream Name"
              variant="outlined"
              type="text"
              placeholder="Stream Name"
              onKeyPress={onKeyPress}
              error={!!localState.streamError}
              helperText={localState.streamError}
            />
          )}
          renderOption={(option) => <Typography noWrap>{option}</Typography>}
        />

        <Autocomplete
          id="virtualize-demo"
          freeSolo
          disableListWrap
          classes={classes}
          ListboxComponent={
            ListboxComponent as React.ComponentType<
              React.HTMLAttributes<HTMLElement>
            >
          }
          options={localState.epicNameSuggestions}
          css={css`
            width: 200px;
          `}
          openOnFocus
          inputValue={localState.epicName}
          onInputChange={(event, newInputValue, reason) => {
            if (event !== null) {
              localState.epicName = newInputValue;
              localState.epicError = undefined;
            }
          }}
          disabled={state.isAddingStream}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Epic Name"
              variant="outlined"
              type="text"
              placeholder="Epic Name"
              onKeyPress={onKeyPress}
              error={!!localState.epicError}
              helperText={localState.epicError}
            />
          )}
          renderOption={(option) => <Typography noWrap>{option}</Typography>}
        />

        {state.isAddingStream ? (
          <CircularProgress />
        ) : (
          <Button color="primary" onClick={addStream}>
            {buttonText}
          </Button>
        )}
      </div>
    );
  }
);
