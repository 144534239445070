/** @jsx jsx */
import { FC } from "react";
import { css, jsx } from "@emotion/core";
import { observer } from "mobx-react-lite";
import ReactModal from "react-modal";

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export const Modal: FC<ModalProps> = observer(
  ({ children, isOpen, onRequestClose }) => {
    return (
      <ReactModal
        css={css`
          position: absolute;
          left: 50%;
          top: 50vh;
          transform: translateY(-50%) translateX(-50%);
          display: flex;
          align-items: center;
          justify-contents: center;
        `}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
      >
        {children}
      </ReactModal>
    );
  }
);
