import { css } from "@emotion/core";

export const container = css`
  margin-top: 20px;
`;

export const topBar = css`
  display: flex;
  align-items: center;
`;

export const pointer = css`
  cursor: pointer;
`;
