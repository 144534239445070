import PouchDB from "pouchdb";
import find from "pouchdb-find";
import { addNewFavorite, db as favoritesDb } from "./db_favorites";
import { addStreamToStreamList, State } from "state";

PouchDB.plugin(find);

export const db = new PouchDB<{
  groupName: string;
  streams: string[];
}>("favorite_groups");

export async function createFavoriteGroup(
  groupName: string,
  streams: { streamName: string; epicName?: string }[]
) {
  try {
    let existing = await getFavoriteGroup(groupName);

    let ids = [];
    for (let { streamName, epicName } of streams) {
      let id = await addNewFavorite(streamName, epicName);

      if (id !== undefined) {
        ids.push(id);
      }
    }

    if (existing === undefined) {
      let res = await db.post({
        groupName,
        streams: ids,
      });

      return res.id;
    } else {
      let res = await db.put({
        _id: existing._id,
        _rev: existing._rev,
        groupName: groupName,
        streams: ids,
      });

      return res.id;
    }
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function getAllFavoriteGroups() {
  try {
    let doc = await db.find({
      selector: {
        groupName: {
          $exists: true,
        },
      },
      sort: ["groupName"],
    });

    return doc.docs;
  } catch (err) {
    console.log(err);
    return [];
  }
}

export async function getFavoriteGroup(groupName: string) {
  try {
    let doc = await db.find({
      selector: {
        groupName: {
          $eq: groupName,
        },
      },
    });

    return doc.docs[0];
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function getFavoriteGroupById(id: string) {
  try {
    let doc = await db.get(id);

    return doc;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function addAllFromGroupToStreamList(id: string, state: State) {
  try {
    let doc = await db.get(id);

    let streams = await favoritesDb.find({
      selector: {
        _id: {
          $in: doc.streams,
        },
      },
    });

    for (let { streamName, epicName } of streams.docs) {
      addStreamToStreamList(state, streamName, epicName);
    }
  } catch (err) {
    console.log(err);
  }
}

export async function deleteFavoriteFromGroup(
  groupId: string,
  favoriteId: string
) {
  try {
    let group = await db.get(groupId);

    let streams = group.streams.filter((stream) => stream !== favoriteId);

    await db.post({
      ...group,
      streams,
    });
  } catch (err) {
    console.log(err);
  }
}

export async function deleteFavoriteGroup(groupName: string) {
  try {
    let doc = await getFavoriteGroup(groupName);

    if (doc !== undefined) {
      await db.remove(doc._id, doc._rev);
    }
  } catch (err) {
    console.log(err);
    return false;
  }
}

try {
  db.createIndex({
    index: {
      fields: ["groupName"],
    },
  }).then((result) => console.log(result));
} catch (err) {
  console.log(err);
}
