import React from "react";
import { useLocalStore } from "mobx-react-lite";

interface State {
  value: string;
  error?: string;
}

export function useTextField(): [
  State,
  (e: React.ChangeEvent<HTMLInputElement>) => void
] {
  const localStore = useLocalStore<State>(() => ({ value: "" }));

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    localStore.value = e.target.value;
  };

  return [localStore, onChange];
}
