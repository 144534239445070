/** @jsx jsx */
import { FC, Fragment } from "react";
import { jsx } from "@emotion/core";
import { observer, useLocalStore } from "mobx-react-lite";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

import { FavoritesTableRow } from "components/favorites/favorites_table_row";
import { EditFavoriteModal } from "components/favorites/edit_favorite_modal";

interface Props {
  favorites: {
    streamName: string;
    epicName?: string;
    id: string;
  }[];
  onRemove: (id: string) => void;
  forceUpdate: () => void;
}

export interface LocalStore {
  modalOpen: boolean;
  editingStreamId?: string;
}

export const FavoritesTable: FC<Props> = observer(
  ({ favorites, onRemove, forceUpdate }) => {
    const localStore = useLocalStore<LocalStore>(() => ({ modalOpen: false }));

    return (
      <Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Stream Name</TableCell>

              <TableCell>Epic Name</TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {favorites.map(({ id, streamName, epicName }) => (
              <FavoritesTableRow
                key={streamName + epicName}
                localStore={localStore}
                streamName={streamName}
                epicName={epicName}
                id={id}
                onRemove={onRemove}
                forceUpdate={forceUpdate}
              />
            ))}
          </TableBody>
        </Table>

        <EditFavoriteModal
          isOpen={localStore.modalOpen}
          onRequestClose={() => (localStore.modalOpen = false)}
          forceUpdate={forceUpdate}
          favoriteId={localStore.editingStreamId}
        />
      </Fragment>
    );
  }
);
