/** @jsx jsx */
import { useEffect } from "react";
import { jsx } from "@emotion/core";
import { observer, useLocalStore } from "mobx-react-lite";
import { IconButton, Typography, Container } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";

import { getFavoriteGroupById } from "./db_favorite_groups";
import { getFavoritesById } from "./db_favorites";
import { deleteFavoriteFromGroup } from "./db_favorite_groups";
import * as styles from "./favorite_group_individual_styles";
import { useForceUpdate } from "hooks/use_force_update";
import { FavoritesTable } from "components/favorites/favorites_table";

interface LocalStore {
  groupName?: string;
  streams: { id: string; streamName: string; epicName?: string }[];
}

export const FavoriteGroupIndividual = observer(() => {
  const localStore = useLocalStore<LocalStore>(() => ({ streams: [] }));

  const history = useHistory();

  const { favoriteGroupId } = useParams<{ favoriteGroupId: string }>();

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    (async () => {
      let group = await getFavoriteGroupById(favoriteGroupId);

      if (group === undefined) {
        history.push("/favorite-groups");
        return;
      }

      localStore.groupName = group.groupName;
      localStore.streams = (await getFavoritesById(group.streams)).map(
        ({ _id, streamName, epicName }) => ({
          id: _id,
          streamName,
          epicName,
        })
      );
    })();
  });

  return (
    <Container>
      <div css={styles.topBar}>
        <IconButton onClick={() => history.push("/favorite-groups")}>
          <ArrowBack />
        </IconButton>

        <Typography variant="h5">
          Favorite Group: {localStore.groupName}
        </Typography>
      </div>

      <FavoritesTable
        favorites={localStore.streams}
        forceUpdate={forceUpdate}
        onRemove={(id) => {
          deleteFavoriteFromGroup(favoriteGroupId, id);
        }}
      />
    </Container>
  );
});
