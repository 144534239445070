/** @jsx jsx */
import { FC, useEffect } from "react";
import { jsx } from "@emotion/core";
import { observer } from "mobx-react-lite";
import { Paper, TextField, Button } from "@material-ui/core";

import { Modal } from "components/Modal";
import { useTextField } from "hooks/use_text_field";
import * as styles from "./edit_favorite_modal_styles";
import { getFavorite, editFavorite, getFavoriteById } from "./db_favorites";

interface Props {
  isOpen: boolean;
  favoriteId?: string;
  onRequestClose: () => void;
  forceUpdate: () => void;
}

export const EditFavoriteModal: FC<Props> = observer(
  ({ isOpen, favoriteId, onRequestClose, forceUpdate }) => {
    const [
      streamNameTextFieldState,
      streamNameTextFieldOnChange,
    ] = useTextField();

    const [epicNameTextFieldState, epicNameTextFieldOnChange] = useTextField();

    useEffect(() => {
      if (favoriteId !== undefined) {
        (async function () {
          let favorite = await getFavoriteById(favoriteId);

          if (favorite !== undefined) {
            streamNameTextFieldState.value = favorite.streamName;
            streamNameTextFieldState.error = undefined;
            epicNameTextFieldState.value = favorite.epicName ?? "";
            epicNameTextFieldState.error = undefined;
          }
        })();
      }
      // eslint-disable-next-line
    }, [favoriteId]);

    const save = async () => {
      const cleanedStreamName = streamNameTextFieldState.value
        .toLowerCase()
        .trim();
      const cleanedEpicName = epicNameTextFieldState.value.toLowerCase().trim();

      if (cleanedStreamName === "") {
        streamNameTextFieldState.error = "You cannot favorite an empty stream";
        return;
      }

      let existingFavorite = await getFavorite(
        cleanedStreamName,
        cleanedEpicName
      );

      if (
        existingFavorite !== undefined &&
        existingFavorite._id !== favoriteId
      ) {
        streamNameTextFieldState.error =
          "You already have that stream and Epic name combination";

        return;
      }

      if (favoriteId !== undefined) {
        await editFavorite(favoriteId, cleanedStreamName, cleanedEpicName);
      }

      streamNameTextFieldState.error = undefined;
      streamNameTextFieldState.value = "";

      onRequestClose();
      forceUpdate();
    };

    return (
      <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <Paper css={styles.modalContainer}>
          <TextField
            label="Stream Name"
            variant="outlined"
            type="text"
            value={streamNameTextFieldState.value}
            onChange={streamNameTextFieldOnChange}
            error={streamNameTextFieldState.error !== undefined}
            helperText={streamNameTextFieldState.error}
          />

          <TextField
            label="Epic Name"
            variant="outlined"
            type="text"
            value={epicNameTextFieldState.value}
            onChange={epicNameTextFieldOnChange}
          />

          <Button color="primary" onClick={save}>
            Save
          </Button>

          <Button color="secondary" onClick={onRequestClose}>
            Cancel
          </Button>
        </Paper>
      </Modal>
    );
  }
);
