/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useEffect } from "react";
import { v4 } from "uuid";
import {
  observer,
  useLocalStore,
  useAsObservableSource,
} from "mobx-react-lite";
import { autorun } from "mobx";

declare global {
  interface Window {
    Twitch: any;
  }
}

interface TwitchState {
  divId: string;
  embed: any;
  videoReady: boolean;
}

interface TwitchProps {
  streamName: string;
  muted?: boolean;
  quality?: "low" | "high";
  className?: string;
}

export const Twitch = observer(
  ({ streamName, className, muted = true, quality = "high" }: TwitchProps) => {
    const localState = useLocalStore<TwitchState, TwitchProps>(() => ({
      divId: v4(),
      embed: null,
      videoReady: false,
    }));

    const observableProps = useAsObservableSource<TwitchProps>({
      streamName,
      className,
      muted,
      quality,
    });

    // const state = useGlobalState();

    useEffect(() => {
      localState.embed = new window.Twitch.Embed(localState.divId, {
        channel: streamName,
        layout: "video",
        autoplay: "true",
      });

      localState.embed.addEventListener(window.Twitch.Embed.VIDEO_READY, () => {
        localState.videoReady = true;
      });
      // eslint-disable-next-line
    }, [streamName]);

    useEffect(
      () =>
        autorun(() => {
          if (localState.embed === null || !localState.videoReady) {
            return;
          }

          localState.embed
            .getPlayer()
            .setMuted(
              observableProps.muted === undefined ? true : observableProps.muted
            );

          if (observableProps.quality !== undefined) {
            localState.embed
              .getPlayer()
              .setQuality(
                observableProps.quality === "high" ? "chunked" : "160p"
              );
          } else {
            localState.embed.getPlayer().setQuality("auto");
          }
        }),
      // eslint-disable-next-line
      []
    );

    return (
      <div
        css={css`
          position: relative;
          // cursor: pointer;
        `}
        className={className}
        // onClick={() => {
        //   if (state.currentStream === null) {
        //     state.currentStream = streamName;
        //   } else {
        //     state.currentStream = null;
        //   }
        // }}
      >
        <div
          css={css`
            height: 100%;
            width: 100%;
            iframe {
              width: 100%;
              height: 100%;
            }
          `}
          id={localState.divId}
        />
        <div
          css={css`
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0px;
            top: 0px;
            pointer-events: none;
          `}
          className="border"
        />
      </div>
    );
  }
);
