import { css } from "@emotion/core";

export const paper = css`
  padding: 20px;
  display: flex;
  width: 100%;
`;

export const iconButtonContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const textField = css`
  width: 100%;
`;
