import { css } from "@emotion/core";

export const container = css`
  padding-top: 20px;
`;

export const modalContainer = css`
  padding: 20px;

  display: flex;

  align-items: center;
`;

export const cursor = css`
  cursor: pointer;
`;

export const iconContainer = css``;

export const favoriteHeader = css`
  display: flex;
  align-items: center;
`;
