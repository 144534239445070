export async function checkTwitch(user: string) {
  try {
    let res = await fetch(
      `${process.env.REACT_APP_API_URL}/checkTwitch?user=${user}`
    );
    let r = await res.json();

    return r;
  } catch {
    return { isOkay: true };
  }
}
