/** @jsx jsx */
import { observer } from "mobx-react-lite";
import { css, jsx } from "@emotion/core";
import { withStyles } from "@material-ui/core/styles";
import { useLocalStore } from "mobx-react-lite";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";

import { Stream } from "components/stream";
import { useGlobalState, StreamInfo } from "state";

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableCell);

interface StreamListState {
  orderBy: keyof StreamInfo;
  order: "asc" | "desc";
  sortFunction: (a: any, b: any) => number;
}

function compareStrings(a: string, b: string) {
  return a.localeCompare(b);
}

function compareNums(a: number | null, b: number | null) {
  a = a || -1;
  b = b || -1;

  return a - b;
}

const headers: {
  id: keyof StreamInfo;
  text: string;
  sortFunction: (a: any, b: any) => number;
  align?: "right";
  sortDefault?: "asc" | "desc";
}[] = [
  {
    id: "streamName",
    text: "Stream",
    sortFunction: compareStrings,
  },
  {
    id: "endgameScore",
    text: "Endgame Probability",
    sortFunction: compareNums,
    align: "right",
    sortDefault: "desc",
  },
  {
    id: "score",
    text: "Score",
    sortFunction: compareNums,
    align: "right",
    sortDefault: "desc",
  },
];

export const StreamList = observer(() => {
  const state = useGlobalState();

  const streamListState = useLocalStore<StreamListState>(() => ({
    orderBy: "streamName",
    order: "asc",
    sortFunction: compareStrings,
  }));

  function onRemoveClick(streamName: string) {
    if (state.currentStream === streamName) {
      state.currentStream = null;
    }

    let i = -1;
    for (i = 0; i < state.streamList.length; ++i) {
      if (state.streamList[i].streamName === streamName) {
        break;
      }
    }
    state.streamList.splice(i, 1);

    state.highlightedStream = null;
  }

  let sortedStreams = state.streamList
    .slice()
    .sort(
      (a, b) =>
        (streamListState.order === "asc" ? 1 : -1) *
        streamListState.sortFunction(
          a[streamListState.orderBy],
          b[streamListState.orderBy]
        )
    );

  return (
    <Table>
      <TableHead
        css={css`
          background-color: black;
        `}
      >
        <TableRow>
          <StyledTableCell />
          {headers.map((header) => (
            <StyledTableCell key={header.text} align={header.align}>
              <TableSortLabel
                active={streamListState.orderBy === header.id}
                direction={
                  streamListState.orderBy === header.id
                    ? streamListState.order
                    : "asc"
                }
                onClick={() => {
                  if (streamListState.orderBy === header.id) {
                    streamListState.order =
                      streamListState.order === "asc" ? "desc" : "asc";
                  } else {
                    streamListState.orderBy = header.id;
                    streamListState.order = header.sortDefault || "asc";
                    streamListState.sortFunction = header.sortFunction;
                  }
                }}
              >
                {header.text}
              </TableSortLabel>
            </StyledTableCell>
          ))}

          <StyledTableCell />
        </TableRow>
      </TableHead>

      <TableBody>
        {sortedStreams.map((stream) => (
          <Stream
            stream={stream}
            key={stream.streamName}
            onRemoveClick={() => onRemoveClick(stream.streamName)}
          />
        ))}
      </TableBody>
    </Table>
  );
});
