/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { observer } from "mobx-react-lite";
import Paper from "@material-ui/core/Paper";

import { AddStream } from "components/AddStream";
import { MultiTwitch } from "components/MultiTwitch";
import { useGlobalState } from "state";

const mainStreamStyle = css`
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: black;
`;

export const MainStream = observer(() => {
  const state = useGlobalState();

  return (
    <div css={mainStreamStyle}>
      {state.streamList.length === 0 ? (
        <Paper>
          <AddStream />
        </Paper>
      ) : (
        <MultiTwitch />
      )}
    </div>
  );
});
