/** @jsx jsx */
import { jsx } from "@emotion/core";
import { observer } from "mobx-react-lite";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { AdminList } from "admin/AdminList";
import { AdminSignIn } from "admin/AdminSignIn";

export const Admin = observer(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/signin`}>
        <AdminSignIn />
      </Route>

      <Route path={`${path}`}>
        <AdminList />
      </Route>
    </Switch>
  );
});
