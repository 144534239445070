/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useRef } from "react";
import { observer } from "mobx-react-lite";
import { Paper, TextField, IconButton } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";

import { Modal } from "components/Modal";
import { NavbarState } from "components/Navbar";
import { useGlobalState } from "state";
import * as styles from "./share_modal_styles";

export const ShareModal = observer(
  ({ navbarState }: { navbarState: NavbarState }) => {
    const textFieldRef = useRef<HTMLInputElement>();
    const state = useGlobalState();

    const shareString = encodeURIComponent(
      JSON.stringify(
        state.streamList.map(({ streamName, epicName }) => ({
          s: streamName,
          e: epicName,
        }))
      )
    );

    return (
      <Modal
        isOpen={navbarState.shareModalOpen}
        onRequestClose={() => (navbarState.shareModalOpen = false)}
      >
        <Paper css={styles.paper}>
          <TextField
            variant="outlined"
            css={styles.textField}
            autoFocus
            value={`${process.env.REACT_APP_SITE_URL}/share/?${shareString}`}
            onFocus={(e) => e.target.select()}
            inputRef={textFieldRef}
          />

          <div css={styles.iconButtonContainer}>
            <IconButton
              onClick={() => {
                textFieldRef.current?.select();
                document.execCommand("copy");
                alert("Copied to clipboard");
              }}
            >
              <FileCopy />
            </IconButton>
          </div>
        </Paper>
      </Modal>
    );
  }
);
