/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { observer, useLocalStore } from "mobx-react-lite";
import {
  CircularProgress,
  MenuItem,
  IconButton,
  Typography,
  Select,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import useSWR from "swr";
import { useHistory } from "react-router-dom";

import { EventsTable } from "components/EventsTable";
import { EntriesTable } from "components/EntriesTable";

const regions: string[] = [
  "None",
  "NAE",
  "NAW",
  "EU",
  "OCE",
  "BR",
  "ASIA",
  "ME",
];

interface GameInfo {
  kills: number;
  placement: number;
}

interface EntryInfo {
  players: { epicName: string; twitchName: string | null; isLive: number }[];
  score: number;
  history: GameInfo[];
}

interface EventInfo {
  name: string;
  displayName: string;
  live: boolean;
  startDate: string;
  lastUpdated: string;
}

export interface LeaderboardState {
  events: EventInfo[];
  selectedEvent?: EventInfo;
  entries: EntryInfo[];
  region?: string;
  eventsLoading: boolean;
  entriesLoading: boolean;
}

export const Leaderboard = observer(() => {
  const history = useHistory();

  const localState = useLocalStore<LeaderboardState>(() => ({
    events: [],
    entries: [],
    eventsLoading: false,
    entriesLoading: false,
    region: "",
  }));

  const { data } = useSWR(
    ["/events", localState.region],
    async (_key, region) => {
      let body;

      if (region === undefined || region === "None" || region === "") {
        body = {};
      } else {
        body = { region: localState.region };
      }

      let res = await fetch(process.env.REACT_APP_API_URL + "/events", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      let json = await res.json();

      return json;
    }
  );

  const { data: playerData } = useSWR(
    localState.selectedEvent !== undefined
      ? ["/players", localState.selectedEvent.name]
      : null,
    async (_key, eventName) => {
      let res = await fetch(process.env.REACT_APP_API_URL + "/players", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ event: eventName }),
      });

      let json = await res.json();

      return json;
    }
  );

  return (
    <div
      css={css`
        height: 100%;
        overflow: auto;
      `}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(2, max-content);
          align-items: center;
          margin: 0px 16px;
        `}
      >
        <IconButton
          onClick={
            localState.selectedEvent !== undefined
              ? () => (localState.selectedEvent = undefined)
              : () => history.push("/")
          }
        >
          <ArrowBack />
        </IconButton>

        <Typography
          variant="h5"
          css={css`
            padding: 12px 0px;
          `}
        >
          {localState.selectedEvent !== undefined
            ? localState.selectedEvent.displayName
            : "Leaderboard"}
        </Typography>
      </div>

      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(2, max-content);
          align-items: center;
          margin: 0px 16px;
        `}
      >
        {localState.selectedEvent === undefined ? (
          <Typography
            variant="subtitle1"
            css={css`
              padding: 12px 0px;
            `}
          >
            Filter by Region:
            <Select
              onChange={(e) => (localState.region = e.target.value as string)}
              value={localState.region}
              css={css`
                margin-left: 20px;
              `}
            >
              {regions.map((region) => (
                <MenuItem value={region === "None" ? "" : region}>
                  {region}
                </MenuItem>
              ))}
            </Select>
          </Typography>
        ) : (
          <Typography
            variant="subtitle1"
            css={css`
              padding: 12px 0px;
            `}
          >
            Last Updated:{" "}
            {new Date(localState.selectedEvent.lastUpdated).toLocaleString()}
          </Typography>
        )}
      </div>

      {data === undefined ||
      (localState.selectedEvent && playerData === undefined) ? (
        <div
          css={css`
            text-align: center;
          `}
        >
          <CircularProgress color="primary" />
        </div>
      ) : null}

      {localState.selectedEvent !== undefined
        ? playerData !== undefined && (
            <EntriesTable
              localState={localState}
              entries={playerData.entries}
            />
          )
        : data !== undefined && (
            <EventsTable events={data.events} localState={localState} />
          )}
    </div>
  );
});
