import React, { createContext, useContext } from "react";
import { observable, autorun } from "mobx";
import * as t from "io-ts";

interface ModalState {
  isOpen: boolean;
}

const StreamInfoIO = t.type({
  streamName: t.string,
  endgameScore: t.union([t.number, t.null]),
  modal: t.type({ isOpen: t.boolean }),
  epicName: t.union([t.string, t.undefined]),
  score: t.union([t.number, t.undefined]),
  connected: t.boolean,
});

export type StreamInfo = t.TypeOf<typeof StreamInfoIO>;

const AdminStateIO = t.type({
  password: t.union([t.string, t.undefined]),
});

type AdminState = t.TypeOf<typeof AdminStateIO>;

const StateIO = t.type({
  currentStream: t.union([t.string, t.null]),
  streamList: t.array(StreamInfoIO),
  isAddingStream: t.boolean,
  socketConnected: t.boolean,
  sidebarHidden: t.boolean,
  highlightedStream: t.union([t.string, t.null]),
  admin: AdminStateIO,
});

export type State = t.TypeOf<typeof StateIO>;

function createDefaultState(): State {
  return {
    currentStream: null,
    streamList: [],
    isAddingStream: false,
    socketConnected: false,
    sidebarHidden: false,
    highlightedStream: null,
    admin: { password: undefined },
  };
}

const StoreContext = createContext<State>(createDefaultState());

export function StoreProvider({
  store,
  children,
}: {
  store: State;
  children: any;
}) {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
}

export function useGlobalState(): State {
  return useContext(StoreContext);
}

export function addStreamToStreamList(
  state: State,
  streamName: string,
  epicName: string | undefined
) {
  let savedIndex = undefined;
  for (let i = 0; i < state.streamList.length; ++i) {
    if (
      state.streamList[i].streamName.toLowerCase().trim() ===
      streamName.toLowerCase().trim()
    ) {
      savedIndex = i;
      break;
    }
  }

  if (savedIndex !== undefined) {
    state.streamList[savedIndex].epicName = epicName;
  } else {
    state.streamList.push({
      streamName,
      epicName,
      endgameScore: 0,
      connected: false,
      score: undefined,
      modal: { isOpen: false },
    });
  }
}

export function createStore() {
  let state: State | undefined;

  if (typeof Storage !== "undefined") {
    let str = localStorage.getItem("watchfortnite.live-state");

    try {
      if (str !== null) {
        let parsedState = JSON.parse(str);
        let ioDecoded = StateIO.decode(parsedState);

        if (ioDecoded._tag === "Left") {
          console.log("failed to decode", ioDecoded.left);
          state = undefined;
        } else {
          state = ioDecoded.right;
          state = observable(state);

          // resetting certain parameters
          for (let i = 0; i < state.streamList.length; ++i) {
            state.streamList[i].connected = false;
            state.streamList[i].endgameScore = 0;
          }
          state.socketConnected = false;
          state.isAddingStream = false;
          state.currentStream = null;
          state.highlightedStream = null;

          state.admin.password = undefined;
        }
      }
    } catch {
      state = undefined;
    }
  }

  if (state === undefined) {
    state = observable<State>(createDefaultState());
  }

  if (typeof Storage !== "undefined") {
    autorun(() => {
      localStorage.setItem(
        "watchfortnite.live-state",
        JSON.stringify({ ...state, admin: { password: undefined } })
      );
    });
  }

  return state;
}
