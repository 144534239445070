/** @jsx jsx */
import { useEffect } from "react";
import { jsx } from "@emotion/core";
import { observer, useLocalStore } from "mobx-react-lite";
import {
  Tooltip,
  IconButton,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Info, Delete, ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import { useGlobalState } from "state";
import {
  getAllFavoriteGroups,
  addAllFromGroupToStreamList,
  deleteFavoriteGroup,
} from "./db_favorite_groups";
import * as styles from "./favorite_groups_styles";
import { useForceUpdate } from "hooks/use_force_update";

interface State {
  groups: { groupName: string; id: string }[];
}

export const FavoriteGroups = observer(() => {
  const history = useHistory();

  const globalState = useGlobalState();

  const localStore = useLocalStore<State>(() => ({ groups: [] }));

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    (async function () {
      let groups = await getAllFavoriteGroups();

      localStore.groups = groups.map((group) => ({
        groupName: group.groupName,
        id: group._id,
      }));
    })();
  });

  const deleteFn = async (groupName: string) => {
    let wantTo = window.confirm(
      `This will delete the group ${groupName}. Are you sure you want to proceed?`
    );

    if (wantTo === false) {
      return;
    }

    await deleteFavoriteGroup(groupName);
    forceUpdate();
  };

  return (
    <Container css={styles.container}>
      <div css={styles.topBar}>
        <Tooltip title="Back to stream list">
          <IconButton onClick={() => history.push("/")}>
            <ArrowBack />
          </IconButton>
        </Tooltip>
        <Typography variant="h5">Favorite Groups</Typography>
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Group Name</TableCell>

            <TableCell align="right" />
          </TableRow>
        </TableHead>

        <TableBody>
          {localStore.groups.map((group) => {
            return (
              <TableRow key={group.groupName}>
                <Tooltip title="Click to add all the streams in this list to the stream list">
                  <TableCell
                    css={styles.pointer}
                    onClick={() =>
                      addAllFromGroupToStreamList(group.id, globalState)
                    }
                  >
                    {group.groupName}
                  </TableCell>
                </Tooltip>

                <TableCell align="right">
                  <IconButton
                    onClick={() => history.push(`/favorite-groups/${group.id}`)}
                  >
                    <Info />
                  </IconButton>

                  <Tooltip title="Delete this favorite group">
                    <IconButton onClick={() => deleteFn(group.groupName)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Container>
  );
});
