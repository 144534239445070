/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import { createStore, StoreProvider } from "state";
import * as serviceWorker from "./serviceWorker";

import "mobx-react-lite/batchingForReactDom";

import { CssBaseline } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import { blue, pink } from "@material-ui/core/colors";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { SetBothNames } from "components/set_both_names";
import { Admin } from "admin/Admin";

const darkTheme = createMuiTheme({
  nprogress: {
    color: "#fff",
  },
  palette: {
    primary: {
      main: blue[200],
    },
    secondary: {
      main: pink[200],
    },
    type: "dark",
    background: {
      default: "#121212",
    },
  },
});

const store = createStore();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <StoreProvider store={store}>
        <CssBaseline />
        <Router>
          <Switch>
            <Route path="/admin">
              <Admin />
            </Route>

            <Route path="/share">
              <SetBothNames />
            </Route>

            <Route path="/">
              <App />
            </Route>
          </Switch>
        </Router>
      </StoreProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
