import PouchDB from "pouchdb";
import find from "pouchdb-find";

PouchDB.plugin(find);

export const db = new PouchDB<{ streamName: string; epicName?: string }>(
  "favorites"
);

export async function getAllFavorites() {
  try {
    let docs = await db.find({
      selector: { streamName: { $exists: true } },
      sort: ["streamName", "epicName"],
    });

    return docs.docs;
  } catch {
    return [];
  }
}

export async function addNewFavorite(streamName: string, epicName?: string) {
  try {
    let favorited = await getFavorite(streamName, epicName);

    if (favorited === undefined) {
      let res = await db.post({ streamName, epicName });
      return res.id;
    } else {
      return favorited._id;
    }
  } catch {
    return undefined;
  }
}

export async function getFavorite(streamName: string, epicName?: string) {
  try {
    let docs = await db.find({
      selector: {
        streamName: { $eq: streamName },
        epicName: { $eq: epicName },
      },
    });

    if (docs.docs.length > 0) {
      return docs.docs[0];
    } else {
      return undefined;
    }
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function getFavoritesById(ids: string[]) {
  try {
    let docs = await db.find({
      selector: {
        _id: { $in: ids },
      },
    });

    return docs.docs;
  } catch (err) {
    console.log(err);
    return [];
  }
}

export async function getFavoriteById(id: string) {
  try {
    let favorites = await getFavoritesById([id]);

    return favorites[0];
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export async function removeFavorite(streamName: string, epicName?: string) {
  try {
    let docs = await db.find({
      selector: {
        streamName: { $eq: streamName },
        epicName: { $eq: epicName },
      },
    });

    if (docs.docs.length > 0) {
      await db.remove(docs.docs[0]);
    }
  } catch {
    return;
  }
}

export async function removeFavoriteById(id: string) {
  try {
    let docs = await db.get(id);

    await db.remove(docs._id, docs._rev);
  } catch (err) {
    console.log(err);
  }
}

export async function editFavorite(
  id: string,
  newStreamName: string,
  newEpicName?: string
) {
  try {
    let doc = await db.get(id);

    let res = await db.put({
      _id: id,
      _rev: doc._rev,
      streamName: newStreamName,
      epicName: newEpicName,
    });

    return res.ok;
  } catch (err) {
    console.log(err);
    return false;
  }
}

try {
  db.createIndex({
    index: {
      fields: ["streamName", "epicName"],
    },
  }).then((result) => console.log(result));
} catch (err) {
  console.log(err);
}
