/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useEffect, useRef } from "react";
import { observer, useLocalStore } from "mobx-react-lite";

import { useGlobalState } from "state";
import { Twitch } from "components/Twitch";
import { pink } from "@material-ui/core/colors";

const r = 16 / 9;

function getOptimal(W: number, H: number, N: number): [number, number] {
  // computing the maximum number of width and height
  let num_w = Math.floor(Math.sqrt((W * N) / (H * r)));
  let num_h = Math.floor(Math.sqrt((H * N * r) / W));

  while (num_w * Math.floor((H * r * num_w) / W) < N) {
    num_w++;
  }

  while (num_h * Math.floor((W * num_h) / (H * r)) < N) {
    num_h++;
  }

  let width = Math.max(W / num_w, (H / num_h) * r);
  let height = width / r;

  let arg = W / num_w > (H / num_h) * r ? 0 : 1;

  let actual_num_w;
  let actual_num_h;

  if (arg === 0) {
    actual_num_w = Math.floor(W / width);
    actual_num_h = Math.ceil(N / actual_num_w);
  } else {
    actual_num_h = Math.floor(H / height);
    actual_num_w = Math.ceil(N / actual_num_h);
  }

  return [actual_num_w, actual_num_h];
}

interface MultiTwitchState {
  width: number;
  height: number;
  optimal: [number, number];
}

export const MultiTwitch = observer(() => {
  const state = useGlobalState();
  const divRef = useRef<HTMLDivElement>(null);
  const localState = useLocalStore<MultiTwitchState>(() => ({
    width: divRef.current?.offsetWidth || 100,
    height: divRef.current?.offsetHeight || 100,
    get optimal(): [number, number] {
      return getOptimal(
        localState.width,
        localState.height,
        state.streamList.length
      );
    },
  }));

  useEffect(() => {
    function getHeightWidth() {
      localState.width = divRef.current?.offsetWidth || 100;
      localState.height = divRef.current?.offsetHeight || 100;
    }
    window.addEventListener("resize", getHeightWidth);

    getHeightWidth();

    return () => {
      window.removeEventListener("resize", getHeightWidth);
    };
  });

  return (
    <div
      ref={divRef}
      css={css`
        height: 100%;
        width: 100%;
        position: relative;
      `}
    >
      <div
        css={[
          css`
            display: grid;
            grid-template-rows: repeat(${localState.optimal[1]}, 1fr);
            grid-template-columns: repeat(${localState.optimal[0]}, 1fr);
            height: 100%;
            width: 100%;
          `,
          state.currentStream &&
            css`
              grid-template-rows: auto;
              grid-template-columns: auto;
            `,
        ]}
      >
        {state.streamList.map(({ streamName, endgameScore }, i) => {
          return (
            <Twitch
              key={"twitch" + streamName}
              css={[
                css`
                  min-width: 0;
                  min-height: 0;
                `,
                (endgameScore ?? 0) > 75
                  ? css`
                      .border {
                        border: 7px solid ${pink[200]};
                      }
                    `
                  : null,
                state.highlightedStream?.toLowerCase() ===
                streamName.toLowerCase()
                  ? css`
                      .border {
                        border: 7px solid white;
                      }
                    `
                  : null,
                state.currentStream &&
                  css`
                    display: none;
                  `,
                state.currentStream?.toLowerCase() ===
                  streamName.toLowerCase() &&
                  css`
                    display: block;
                    .border {
                      border: none;
                    }
                  `,
              ]}
              streamName={streamName}
              muted={
                state.currentStream?.toLowerCase() !== streamName.toLowerCase()
              }
              quality={
                state.currentStream?.toLowerCase() === streamName.toLowerCase()
                  ? "high"
                  : "low"
              }
            />
          );
        })}
      </div>
    </div>
  );
});
