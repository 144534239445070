/** @jsx jsx */
import { FC, useCallback } from "react";
import { jsx } from "@emotion/core";
import { observer } from "mobx-react-lite";
import { TableRow, TableCell, Tooltip, IconButton } from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";

import { useGlobalState, addStreamToStreamList } from "state";
import { LocalStore as FavoritesTableLocalStore } from "components/favorites/favorites_table";
import * as styles from "./favorites_table_row_styles";

interface Props {
  localStore: FavoritesTableLocalStore;
  id: string;
  streamName: string;
  epicName?: string;
  onRemove: (id: string) => void;
  forceUpdate: () => void;
}

export const FavoritesTableRow: FC<Props> = observer(
  ({ id, streamName, epicName, forceUpdate, localStore, onRemove }) => {
    const globalState = useGlobalState();

    const addFavoriteToStreamList = useCallback(
      () =>
        addStreamToStreamList(globalState, streamName, epicName ?? undefined),
      [globalState, streamName, epicName]
    );

    return (
      <TableRow>
        <Tooltip title="Click to add to the stream list">
          <TableCell css={styles.cursor} onClick={addFavoriteToStreamList}>
            <div>{streamName}</div>
          </TableCell>
        </Tooltip>

        <Tooltip title="Click to add to the stream list">
          <TableCell css={styles.cursor} onClick={addFavoriteToStreamList}>
            {epicName}
          </TableCell>
        </Tooltip>

        <TableCell align="right">
          <Tooltip title="Edit favorite">
            <IconButton
              onClick={() => {
                localStore.editingStreamId = id;
                localStore.modalOpen = true;
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete favorite">
            <IconButton
              onClick={() => {
                onRemove(id);
                forceUpdate();
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  }
);
