import { css } from "@emotion/core";

export const iconPointer = css`
  display: block;
  cursor: pointer;
`;

export const pointer = css`
  cursor: pointer;
`;

export const fitContent = css`
  width: fit-content;
`;

export const alignVertical = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const buttonContainer = css`
  ${alignVertical}
  justify-content: center;
`;

export const block = css`
  display: block;
`;

export const emojiCell = css`
  padding: 16px 0px 16px 16px;
`;

export const scoreCell = css`
  width: fit-content;
  margin: 0px 0px 0px auto;
`;
