import { css } from "@emotion/core";

export const cursor = css`
  pointer: cursor;
`;

export const iconContainer = css`
  display: flex;
  align-items: center;
`;
