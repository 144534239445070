/** @jsx jsx */
import { FC } from "react";
import { jsx } from "@emotion/core";
import { observer } from "mobx-react-lite";
import { Paper, TextField, Button } from "@material-ui/core";

import { Modal } from "components/Modal";
import { useTextField } from "hooks/use_text_field";
import {
  getFavoriteGroup,
  createFavoriteGroup,
} from "components/favorites/db_favorite_groups";
import { useGlobalState } from "state";
import * as styles from "./new_favorite_group_modal_styles";

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
}

export const NewFavoriteGroupModal: FC<Props> = observer(
  ({ isOpen, onRequestClose }) => {
    const [textFieldState, textFieldOnChange] = useTextField();

    const globalState = useGlobalState();

    const save = async () => {
      let cleanGroupName = textFieldState.value.trim();
      if (cleanGroupName === "") {
        textFieldState.error = "You cannot add a favorite group with no name";
      }

      let existing = await getFavoriteGroup(cleanGroupName);

      if (existing !== undefined) {
        let wantTo = window.confirm(
          "You already have a favorite group with that name. Saving now will overwrite that group with this one. Do you want to proceed?"
        );

        if (wantTo === false) {
          return;
        }
      }

      await createFavoriteGroup(
        cleanGroupName,
        globalState.streamList.map(({ streamName, epicName }) => ({
          streamName: streamName.toLowerCase().trim(),
          epicName: epicName?.toLowerCase().trim(),
        }))
      );

      textFieldState.value = "";
      textFieldState.error = undefined;

      onRequestClose();
    };

    return (
      <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <Paper css={styles.paper}>
          <TextField
            label="Favorite Group Name"
            placeholder="Favorite Group Name"
            variant="outlined"
            value={textFieldState.value}
            onChange={textFieldOnChange}
            error={textFieldState.error !== undefined}
            helperText={textFieldState.error}
          />

          <Button color="primary" onClick={save}>
            Save Group
          </Button>
        </Paper>
      </Modal>
    );
  }
);
