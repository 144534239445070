/** @jsx jsx */
import React, { useEffect } from "react";
import { css, jsx } from "@emotion/core";
import { Paper, TableRow, Tooltip, Typography } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { observer, useLocalStore } from "mobx-react-lite";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import useSWR from "swr";

import { API_URL, MODEL_API_URL } from "api";
import { StyledTableCell } from "components/StreamList";
import { EditStream } from "components/EditStream";
import { StreamInfo, useGlobalState } from "state";
import {
  addNewFavorite,
  getFavorite,
  removeFavorite,
} from "components/favorites/db_favorites";
import { useForceUpdate } from "hooks/use_force_update";
import * as styles from "./stream_styles";
import { Modal } from "components/Modal";

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface StreamProps {
  onRemoveClick: () => void;
  stream: StreamInfo;
}

interface StreamLocalStore {
  favorited: boolean;
}

export const Stream = observer(({ stream, onRemoveClick }: StreamProps) => {
  const lowerStreamName = stream.streamName.toLowerCase().trim();
  const lowerEpicName = stream.epicName?.toLowerCase().trim();

  const localStore = useLocalStore<StreamLocalStore>(() => ({
    favorited: false,
  }));

  const forceUpdate = useForceUpdate();

  const state = useGlobalState();

  const { data } = useSWR(
    stream.epicName !== undefined ? ["/player_score", stream.epicName] : null,
    async (_key, epicName) => {
      let res = await fetch(
        API_URL + `/player_score?playerName=${encodeURIComponent(epicName)}`
      );

      let json = await res.json();

      stream.score = json.score;

      return json;
    },
    { refreshInterval: 10 * 1000 }
  );

  const { data: modelData } = useSWR(
    stream.streamName,
    async (key) => {
      let res = await fetch(
        MODEL_API_URL + `?stream_name=${encodeURIComponent(key)}`
      );

      let json = await res.json();

      stream.endgameScore = json.endgame_prob;

      return json;
    },
    { refreshInterval: 10 * 1000 }
  );

  useEffect(() => {
    (async function () {
      localStore.favorited =
        (await getFavorite(lowerStreamName, lowerEpicName)) !== undefined;
    })();
  });

  return (
    <React.Fragment>
      <StyledTableRow
        onMouseEnter={() => (state.highlightedStream = stream.streamName)}
        onMouseLeave={() => (state.highlightedStream = null)}
      >
        <StyledTableCell css={styles.emojiCell}>
          <div css={styles.alignVertical}>
            {state.currentStream === stream.streamName && (
              <EmojiObjectsIcon color="primary" />
            )}
          </div>
        </StyledTableCell>
        <StyledTableCell
          css={styles.pointer}
          onClick={() => {
            if (state.currentStream !== stream.streamName) {
              state.currentStream = stream.streamName;
            } else {
              state.currentStream = null;
            }
          }}
        >
          {stream.streamName}
          {stream.epicName && <div> ({stream.epicName})</div>}
        </StyledTableCell>

        <StyledTableCell align="right">
          <div>
            {modelData?.endgame_prob
              ? (modelData.endgame_prob * 100).toFixed(2).toString() + "%"
              : "-"}
          </div>
        </StyledTableCell>

        <StyledTableCell align="right">
          <div css={styles.scoreCell}>
            {data !== undefined ? (
              <Tooltip
                arrow
                title={
                  <React.Fragment>
                    <React.Fragment>
                      <Typography color="inherit">{data.eventName}</Typography>
                      {data.history?.map(
                        ({ placement, kills }: any, i: number) => (
                          <div>
                            Game: {i + 1} Placement: {placement} Kills:{kills}
                          </div>
                        )
                      )}
                    </React.Fragment>
                  </React.Fragment>
                }
              >
                <div css={styles.fitContent}>{data.score}</div>
              </Tooltip>
            ) : stream.epicName ? (
              <Tooltip arrow title="Currently not in event">
                <div css={css``}>-</div>
              </Tooltip>
            ) : (
              <Tooltip
                arrow
                title="You need to add a valid Epic name to see scores here"
              >
                <div css={styles.fitContent}>?</div>
              </Tooltip>
            )}
          </div>
        </StyledTableCell>

        <StyledTableCell>
          <div css={styles.buttonContainer}>
            {localStore.favorited ? (
              <StarIcon
                css={styles.iconPointer}
                onClick={async () => {
                  await removeFavorite(lowerStreamName, lowerEpicName);
                  forceUpdate();
                }}
              />
            ) : (
              <StarBorderIcon
                css={styles.iconPointer}
                onClick={async () => {
                  await addNewFavorite(lowerStreamName, lowerEpicName);
                  forceUpdate();
                }}
              />
            )}

            <EditIcon
              css={styles.iconPointer}
              onClick={() => (stream.modal.isOpen = true)}
            />

            <DeleteIcon css={styles.iconPointer} onClick={onRemoveClick} />
          </div>
        </StyledTableCell>
      </StyledTableRow>

      <Modal
        isOpen={stream.modal.isOpen}
        onRequestClose={() => (stream.modal.isOpen = false)}
      >
        <Paper>
          <EditStream stream={stream} />
        </Paper>
      </Modal>
    </React.Fragment>
  );
});
