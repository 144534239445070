/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import ReactModal from "react-modal";
import { observer } from "mobx-react-lite";
import { Paper, ListItem, List, Typography } from "@material-ui/core";

import { NavbarState } from "components/Navbar";

export const HowToUseModal = observer(
  ({ navbarState }: { navbarState: NavbarState }) => {
    return (
      <ReactModal
        style={{
          overlay: {
            zIndex: 10000,
          },
        }}
        css={css`
          position: absolute;
          left: 50%;
          top: 50vh;
          transform: translateY(-50%) translateX(-50%);
          display: block;
          align-items: center;
          justify-content: center;
        `}
        isOpen={navbarState.modalOpen}
        onRequestClose={() => (navbarState.modalOpen = false)}
      >
        <Paper
          css={css`
            height: 100%;
            max-height: calc(100vh - 40px);
            padding: 20px;
            overflow-y: auto;
          `}
        >
          <Typography variant="h6" gutterBottom>
            How to Use
          </Typography>

          <List>
            <ListItem>
              <Typography variant="body1" gutterBottom>
                Add streams by Twitch name. Include an Epic name to see the
                points of the player in their current event. Suggested epic
                names will appear if any epic accounts have been linked to the
                twitch account.
              </Typography>
            </ListItem>

            <ListItem>
              <Typography variant="body1" gutterBottom>
                In the list on the right, you will see a continuously updated
                probability that each player you have added is in endgame. If
                the probability is greater than 75%, the stream in the
                multitwitch view will be highlighted in a red box.
              </Typography>
            </ListItem>

            <ListItem>
              <Typography variant="body1" gutterBottom>
                Click on a name in the list to fullscreen. This will
                automatically unmute it, adjust quality, and expand it to fit
                the left. Click on the name again, or the "Back to MultiTwitch"
                on the navbar to return to the MultiTwitch.
              </Typography>
            </ListItem>

            <ListItem>
              <Typography variant="body1" gutterBottom>
                View a leaderboard of current events through the leaderboard tab
                in the nav menu. The leaderboard will also tell you which
                players are currently streaming. You can add players on the
                leaderboard to your stream by clicking on them. If a player has
                linked their epic and twitch, the twitch name will be filled
                out. Otherwise, twitch name suggestions will be given based on
                crowdsourcing.
              </Typography>
            </ListItem>

            <ListItem>
              <Typography variant="body1" gutterBottom>
                Link your epic and twitch accounts through the link accounts tab
                in the nav menu. This will allow your stream to be added through
                the leaderboard! It will also allow your epic name to be
                suggested when your twitch stream is entered.
              </Typography>
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom>
            FAQ
          </Typography>

          <List>
            <ListItem>
              <Typography variant="body1" gutterBottom>
                Q: How is the endgame probability calculated?
              </Typography>
            </ListItem>

            <ListItem>
              <Typography variant="body1" gutterBottom>
                A: We trained a CNN (convolutional neural network) to take in
                frames of a fortnite stream and return the probability of
                endgame. Endgame was considered to start as soon as half-in,
                half-out zone appears. Note that this number is{" "}
                <strong> not </strong> the absolute truth. It is possible for
                the number to be high and the game not actually be in end game
                and vice versa. However, during our testing, the model was able
                to predict if a given frame is in endgame with very high
                accuracy (&gt; 95%).
              </Typography>
            </ListItem>
          </List>
        </Paper>
      </ReactModal>
    );
  }
);
