/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Redirect, useLocation } from "react-router-dom";
import { useGlobalState, addStreamToStreamList } from "state";

export function SetBothNames() {
  const state = useGlobalState();
  let location = useLocation();

  let jsonBlob = JSON.parse(decodeURIComponent(location.search.slice(1)));
  console.log(jsonBlob);

  state.streamList = [];

  for (let { s, e } of jsonBlob) {
    addStreamToStreamList(state, s, e);
  }

  return <Redirect to="/" />;
}
