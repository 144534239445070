/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Paper,
} from "@material-ui/core";

import { LiveTv, Cancel, Help } from "@material-ui/icons";
import { LeaderboardState } from "components/Leaderboard";
import { observer, useLocalStore } from "mobx-react-lite";
import ReactModal from "react-modal";

import { AddStream } from "components/AddStream";

interface EntriesTableLocalState {
  isModalOpen: boolean;
  epicName?: string;
  twitchName?: string;
}

export const EntriesTable = observer(
  ({ localState, entries }: { localState: LeaderboardState; entries: any }) => {
    const entriesLocalState = useLocalStore<EntriesTableLocalState>(() => ({
      isModalOpen: false,
    }));

    return (
      <Table
        css={css`
          overflow-y: auto;
        `}
      >
        <TableHead>
          <TableRow>
            <TableCell>Is Live?</TableCell>
            <TableCell>Players</TableCell>
            <TableCell>Score</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {entries.map((entry: any) => (
            <TableRow>
              <TableCell>
                {entry.players.map(({ epicName, twitchName, isLive }: any) => (
                  <div>
                    {isLive === 0 ? (
                      <Tooltip title="Not Live">
                        <Cancel color="error" fontSize="small" />
                      </Tooltip>
                    ) : isLive === 1 ? (
                      <Tooltip title="Live!">
                        <LiveTv color="primary" fontSize="small" />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Could be live, no linked twitch">
                        <Help fontSize="small" />
                      </Tooltip>
                    )}
                  </div>
                ))}
              </TableCell>

              <TableCell>
                {entry.players.map(({ epicName, twitchName, isLive }: any) => (
                  <Tooltip title="Click to add to the MultiTwitch">
                    <div
                      onClick={() => {
                        entriesLocalState.isModalOpen = true;
                        entriesLocalState.epicName = epicName;
                        entriesLocalState.twitchName =
                          twitchName === null ? undefined : twitchName;
                      }}
                      css={css`
                        cursor: pointer;
                      `}
                    >
                      {epicName}{" "}
                      {twitchName !== null ? `(${twitchName})` : null}{" "}
                    </div>
                  </Tooltip>
                ))}
              </TableCell>

              <TableCell>
                <Tooltip
                  arrow
                  title={
                    <React.Fragment>
                      {entry.history.map(
                        ({ placement, kills }: any, i: number) => (
                          <div>
                            Game: {i + 1} Placement: {placement} Kills: {kills}
                          </div>
                        )
                      )}
                    </React.Fragment>
                  }
                >
                  <div
                    css={css`
                      width: fit-content;
                    `}
                  >
                    {entry.score}
                  </div>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

        <ReactModal
          css={css`
            position: absolute;
            left: 50%;
            top: 50vh;
            transform: translateY(-50%) translateX(-50%);
            display: flex;
            align-items: center;
            justify-contents: center;
          `}
          // style={{
          //   overlay: {
          //     zIndex: 100000,
          //   },
          // }}
          isOpen={entriesLocalState.isModalOpen}
          onRequestClose={() => (entriesLocalState.isModalOpen = false)}
        >
          <Paper>
            <AddStream
              initialEpicName={entriesLocalState.epicName}
              initialTwitchName={entriesLocalState.twitchName}
              additionalOkayAction={() =>
                (entriesLocalState.isModalOpen = false)
              }
            />
          </Paper>
        </ReactModal>
      </Table>
    );
  }
);
