/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import Split from "react-split";
import { Fab, Tooltip } from "@material-ui/core";
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import { Switch, Route } from "react-router-dom";

import { StreamList } from "components/StreamList";
import { MainStream } from "components/MainStream";
import { AddStream } from "components/AddStream";
import { Favorites } from "components/favorites/favorites";
import { FavoriteGroups } from "components/favorites/favorite_groups";
import { Navbar } from "components/Navbar";
import { useGlobalState } from "state";
import { Leaderboard } from "components/Leaderboard";
import { SetNames } from "components/SetNames";
import { FavoriteGroupIndividual } from "components/favorites/favorite_group_individual";

export interface AppState {
  showLeaderboard: boolean;
}

export const App = observer(() => {
  const state = useGlobalState();
  const localState = useLocalStore<AppState>(() => ({
    showLeaderboard: false,
  }));

  useEffect(() => {
    let interval = setInterval(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const rightBar = css`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
    height: 100%;
    overflow: auto;
  `;

  return (
    <div
      css={css`
        display: block;
        height: 100vh;
        width: 100%;
      `}
    >
      <Split
        css={css`
          display: flex;
          width: 100%;
          height: 100%;
        `}
        sizes={state.sidebarHidden ? [100, 0] : [66, 34]}
        minSize={[100, 0]}
        gutterSize={state.sidebarHidden ? 0 : 10}
        direction="horizontal"
        onDrag={() => {
          window.dispatchEvent(new Event("resize"));
        }}
      >
        <MainStream />

        <div css={rightBar}>
          <Navbar appLocalState={localState} />

          <Switch>
            <Route path="/leaderboard">
              <Leaderboard />
            </Route>

            <Route path="/favorites">
              <Favorites />
            </Route>

            <Route path="/favorite-groups/:favoriteGroupId">
              <FavoriteGroupIndividual />
            </Route>

            <Route path="/favorite-groups">
              <FavoriteGroups />
            </Route>

            <Route path="/" exact>
              <React.Fragment>
                <div
                  css={css`
                    height: 100%;
                    overflow-y: auto;
                  `}
                >
                  <StreamList />
                </div>
                <div
                  css={css`
                    display: grid;
                    grid-template-rows: auto;
                    grid-template-columns: auto 1fr;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <Tooltip title="Hide Sidebar" aria-label="hide-sidebar">
                      <Fab
                        onClick={() => {
                          state.sidebarHidden = true;
                        }}
                        color="primary"
                      >
                        <ArrowForward />
                      </Fab>
                    </Tooltip>
                  </div>
                  <AddStream />
                </div>
              </React.Fragment>
            </Route>

            <Route path="/:twitchName*">
              <SetNames />
            </Route>
          </Switch>
        </div>
      </Split>

      <Tooltip title="Show Sidebar" aria-label="show-sidebar">
        <Fab
          css={[
            css`
              position: absolute;
              right: 16px;
              bottom: 16px;
            `,
            state.sidebarHidden
              ? null
              : css`
                  display: none;
                `,
          ]}
          onClick={() => {
            state.sidebarHidden = false;
          }}
          color="primary"
        >
          <ArrowBack />
        </Fab>
      </Tooltip>
    </div>
  );
});

export default App;
