/** @jsx jsx */
import { useEffect, useCallback, Fragment } from "react";
import { jsx } from "@emotion/core";
import { observer, useLocalStore } from "mobx-react-lite";
import {
  Tooltip,
  IconButton,
  Typography,
  Container,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { Delete, Edit, ArrowBack } from "@material-ui/icons";

import { useGlobalState, addStreamToStreamList } from "state";
import { db, removeFavorite, removeFavoriteById } from "./db_favorites";
import * as styles from "./favorites_styles";
import { useForceUpdate } from "hooks/use_force_update";
import { FavoritesTable } from "./favorites_table";

interface FavoriteProps {
  localStore: FavoritesLocalStore;
  id: string;
  streamName: string;
  epicName?: string;
  forceUpdate: () => void;
}

export const Favorite = observer(
  ({ id, streamName, epicName, localStore, forceUpdate }: FavoriteProps) => {
    const globalState = useGlobalState();

    const addFavoriteToStreamList = useCallback(
      () =>
        addStreamToStreamList(globalState, streamName, epicName ?? undefined),
      [globalState, streamName, epicName]
    );

    return (
      <TableRow>
        <Tooltip title="Click to add to the stream list">
          <TableCell css={styles.cursor} onClick={addFavoriteToStreamList}>
            <div>{streamName}</div>
          </TableCell>
        </Tooltip>

        <Tooltip title="Click to add to the stream list">
          <TableCell css={styles.cursor} onClick={addFavoriteToStreamList}>
            {epicName}
          </TableCell>
        </Tooltip>

        <TableCell>
          <div css={styles.iconContainer}>
            <Tooltip title="Edit favorite">
              <IconButton
                onClick={() => {
                  localStore.editingStream = id;
                  localStore.streamNameTextField = streamName;
                  localStore.streamNameTextFieldError = undefined;
                  localStore.epicNameTextField = epicName ?? "";

                  localStore.modalOpen = true;
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete favorite">
              <IconButton
                onClick={() => {
                  removeFavorite(streamName, epicName);
                  forceUpdate();
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
    );
  }
);

interface FavoritesLocalStore {
  modalOpen: boolean;
  favorites: {
    id: string;
    streamName: string;
    epicName?: string;
  }[];
  editingStream?: string;
  streamNameTextField: string;
  epicNameTextField: string;
  streamNameTextFieldError?: string;
}

export const Favorites = observer(() => {
  const forceUpdate = useForceUpdate();

  const history = useHistory();

  const localStore = useLocalStore<FavoritesLocalStore>(() => ({
    modalOpen: false,
    streamNameTextField: "",
    epicNameTextField: "",
    favorites: [],
  }));

  useEffect(() => {
    const getFavorites = async () => {
      try {
        let docs = await db.find({
          selector: { streamName: { $exists: true } },
          sort: ["streamName", "epicName"],
        });

        localStore.favorites = docs.docs.map(
          ({ _id, streamName, epicName }) => ({
            streamName: streamName ?? "",
            epicName: epicName,
            id: _id,
          })
        );
      } catch {}
    };

    getFavorites();
  });

  return (
    <Fragment>
      <Container css={styles.container}>
        <div css={styles.favoriteHeader}>
          <Tooltip title="Back to stream list">
            <IconButton onClick={() => history.push("/")}>
              <ArrowBack />
            </IconButton>
          </Tooltip>
          <Typography variant="h5">Favorites</Typography>
        </div>

        <FavoritesTable
          favorites={localStore.favorites}
          onRemove={removeFavoriteById}
          forceUpdate={forceUpdate}
        />
      </Container>
    </Fragment>
  );
});
