/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Redirect, useParams } from "react-router-dom";
import { useGlobalState } from "state";

export function SetNames() {
  const state = useGlobalState();
  let { twitchName } = useParams();

  let twitchNames = twitchName.split("/");

  state.streamList = [];
  for (let i = 0; i < twitchNames.length; ++i) {
    state.streamList.push({
      streamName: twitchNames[i],
      endgameScore: 0,
      score: 0,
      modal: {
        isOpen: false,
      },
      connected: false,
      epicName: undefined,
    });
  }

  return <Redirect to="/" />;
}
