/** @jsx jsx */
import { jsx } from "@emotion/core";
import { observer } from "mobx-react-lite";

import { useGlobalState } from "state";
import { StreamInput, StreamInputState } from "components/StreamInput";
import { API_URL } from "api";

export const AddStream = observer(
  ({
    initialTwitchName,
    initialEpicName,
    additionalOkayAction,
  }: {
    initialTwitchName?: string;
    initialEpicName?: string;
    additionalOkayAction?: () => void;
  }) => {
    const state = useGlobalState();

    async function okayAction(
      cleanStreamName: string,
      cleanEpicName: string,
      localState: StreamInputState
    ) {
      state.streamList.push({
        streamName: cleanStreamName,
        epicName: cleanEpicName === "" ? undefined : cleanEpicName,
        endgameScore: 0,
        score: 0,
        modal: {
          isOpen: false,
        },
        connected: false,
      });

      if (cleanStreamName.length > 0 && cleanEpicName.length > 0) {
        await fetch(API_URL + "/link", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            twitchName: cleanStreamName,
            epicName: cleanEpicName,
            hard: false,
          }),
        });
      }

      // clearing the streamName and epicName
      localState.streamName = "";
      localState.epicName = "";
      localState.epicError = undefined;
      localState.streamError = undefined;

      if (additionalOkayAction !== undefined) {
        additionalOkayAction();
      }
    }

    return (
      <StreamInput
        initialEpicName={initialEpicName}
        initialStreamName={initialTwitchName}
        okayAction={okayAction}
        emptyStreamError="You cannot add an empty stream name"
        duplicateStreamError="You've already added a stream by that name"
        duplicateEpicError="You've already added a stream with that Epic name"
        inactiveStreamError="That stream is inactive"
        buttonText="Add Stream"
        checkDuplicateStream={(listStreamName, trimmedStreamName) =>
          listStreamName.toLowerCase() === trimmedStreamName.toLowerCase()
        }
        checkDuplicateEpic={(listEpicName, trimmedEpicName) =>
          listEpicName !== undefined &&
          listEpicName !== "" &&
          listEpicName.toLowerCase() === trimmedEpicName.toLowerCase()
        }
      />
    );
  }
);
