/** @jsx jsx */
import { jsx } from "@emotion/core";
import { observer, useLocalStore } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import { Input } from "@material-ui/core";

import { API_URL } from "api";
import { useGlobalState } from "state";

interface AdminSignInLocalStore {
  password: string;
  isValidating: boolean;
}

export const AdminSignIn = observer(() => {
  const { admin } = useGlobalState();

  const localStore = useLocalStore<AdminSignInLocalStore>(() => ({
    password: "",
    isValidating: false,
  }));

  if (admin.password !== undefined) {
    return <Redirect to="/admin/" />;
  }

  async function submitPassword() {
    localStore.isValidating = true;

    let res = await fetch(API_URL + "/password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password: localStore.password }),
    });

    if (res.status === 200) {
      admin.password = localStore.password;
    } else {
      localStore.password = "";
      alert("Incorrect password");
    }

    localStore.isValidating = false;
  }

  return (
    <div>
      <Input
        disabled={localStore.isValidating}
        value={localStore.password}
        onChange={(e) => {
          localStore.password = e.target.value;
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") submitPassword();
        }}
        type="password"
      />
    </div>
  );
});
