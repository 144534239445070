/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { LeaderboardState } from "components/Leaderboard";
import { observer } from "mobx-react-lite";

export const EventsTable = observer(
  ({ localState, events }: { localState: LeaderboardState; events: any }) => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Event Name</TableCell>
            <TableCell>Start Time </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {events.length ? (
            events.map((event: any) => (
              <TableRow
                css={css`
                  cursor: pointer;
                `}
                onClick={() => (localState.selectedEvent = event)}
              >
                <TableCell>{event.displayName}</TableCell>
                <TableCell>
                  {new Date(event.startTime).toLocaleString()}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell> No Live Events </TableCell>
              <TableCell />
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  }
);
