/** @jsx jsx */
import { jsx } from "@emotion/core";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import useSWR from "swr";
import * as t from "io-ts";
import { PathReporter } from "io-ts/PathReporter";
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";

import { API_URL } from "api";
import { useGlobalState } from "state";

const AdminListIO = t.type({
  events: t.array(
    t.type({
      startTime: t.string,
      endTime: t.string,
      eventName: t.string,
      scraped: t.union([t.undefined, t.boolean]),
      lastUpdated: t.union([t.undefined, t.string, t.null]),
    })
  ),
});

const headers = [
  "Event Name",
  "Start Time",
  "End Time",
  "Last Updated",
  "Rescrape",
];

export const AdminList = observer(() => {
  const { admin } = useGlobalState();

  const { data, error, mutate } = useSWR(
    admin.password ? "/admin/events" : null,
    async (route) => {
      let res = await fetch(API_URL + route, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ password: admin.password }),
      });

      let json = await res.json();

      let decoded = AdminListIO.decode(json);

      if (decoded._tag === "Left") {
        throw new Error(
          "Unexpected API response" + PathReporter.report(decoded)
        );
      }

      return decoded.right;
    }
  );

  if (admin.password === undefined) {
    return <Redirect to="/admin/signin" />;
  }

  if (error) {
    return <div>There was an error ({error.toString()})</div>;
  }

  if (data === undefined) {
    return <div>Loading</div>;
  }

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.events.map((event) => (
            <TableRow key={event.eventName}>
              <TableCell>{event.eventName}</TableCell>

              <TableCell>
                {new Date(event.startTime).toLocaleString()}
              </TableCell>

              <TableCell>{new Date(event.endTime).toLocaleString()}</TableCell>

              <TableCell>
                {event.lastUpdated &&
                  new Date(event.lastUpdated).toLocaleString()}
              </TableCell>

              <TableCell>
                <Button
                  onClick={async () => {
                    let res = await fetch(API_URL + "/admin/update", {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        password: admin.password,
                        eventName: event.eventName,
                      }),
                    });

                    if (res.status === 200) {
                      mutate();
                    } else if (res.status === 401) {
                      admin.password = undefined;
                    }
                  }}
                >
                  Rescrape
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
});
