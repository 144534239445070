/** @jsx jsx */
import { jsx } from "@emotion/core";
import { observer } from "mobx-react-lite";

import { useGlobalState, StreamInfo } from "state";
import { StreamInput, StreamInputState } from "components/StreamInput";
import { API_URL } from "api";

interface EditStreamProps {
  stream: StreamInfo;
}

export const EditStream = observer(({ stream }: EditStreamProps) => {
  const state = useGlobalState();
  async function okayAction(
    cleanStreamName: string,
    cleanEpicName: string,
    _localState: StreamInputState
  ) {
    let oldName = stream.streamName;

    if (stream.epicName?.toLowerCase() !== cleanEpicName.toLowerCase()) {
      stream.score = undefined;
    }

    stream.streamName = cleanStreamName;
    stream.epicName = cleanEpicName;
    stream.connected = false;

    if (cleanStreamName.length > 0 && cleanEpicName.length > 0) {
      await fetch(API_URL + "/link", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          twitchName: cleanStreamName,
          epicName: cleanEpicName,
          hard: false,
        }),
      });
    }

    if (state.currentStream === oldName.toLowerCase()) {
      state.currentStream = null;
    }

    stream.modal.isOpen = false;
  }

  return (
    <StreamInput
      initialStreamName={stream.streamName}
      initialEpicName={stream.epicName}
      okayAction={okayAction}
      emptyStreamError="You cannot set an empty stream name"
      duplicateStreamError="You have already added another stream by that name"
      duplicateEpicError="You have already added another stream with that Epic name"
      inactiveStreamError="That stream is inactive"
      buttonText="Save Stream"
      checkDuplicateStream={(listStreamName, trimmedStreamName) =>
        trimmedStreamName.toLowerCase() !== stream.streamName.toLowerCase() &&
        listStreamName.toLowerCase() === trimmedStreamName.toLowerCase()
      }
      checkDuplicateEpic={(listEpicName, trimmedEpicName) =>
        trimmedEpicName !== "" &&
        stream.epicName !== undefined &&
        trimmedEpicName.toLowerCase() !== stream.epicName.toLowerCase() &&
        listEpicName !== undefined &&
        listEpicName.toLowerCase() === trimmedEpicName.toLowerCase()
      }
    />
  );
});
